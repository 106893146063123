<template>
	<div class="item-wrapper">
		<div v-if="tag" class="tag">
			<span>{{ tag }}</span>
		</div>

		<div v-if="hasActiveDiscount" class="discount-badge">
			<img src="@/assets/discount-icon.svg" alt="Discount" class="discount-icon">
		</div>

		<div class="preview">
			<img v-if="item.category == 'Cases'" @click="goToSliderItem(item, item.category)" :src="item.img_url"
				class="img" />
			<img v-else @click="goToSliderItem(item, item.category)" :src="imgDomain + item.img_url" class="img" />
			<transition name="fade">
				<span class="title" v-if="addToCartChosenItem == item.id">{{ $t(`Added`) }}</span>
			</transition>
		</div>
		<a @click="goToSliderItem(item, item.category)" class="desc">{{ getTitle(item) }}</a>
		<div class="price title">
			<span class="discount" v-if="item.old_price && item.old_price != '0.00'">{{ item.old_price }} <span
					class="currency">{{
			currentCurrencySymbol }}</span></span>
			<span>{{ item.price }} <span class="currency">{{ currentCurrencySymbol }}</span></span>
		</div>
		<!-- <div class="desc">{{item.type}}</div> -->
		<a @click="$emit('addToCart', item, item.id, item.item_type)" class="link button">
			<span>{{ $t(`Add to Cart`) }}</span>
			<img src="./../assets/cart.svg" class="img">
		</a>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag'],
	data: function () {
		return {
			imgDomain: '',
			appDomain: '',
		}
	},
	computed: {
		...mapGetters('discount', ['hasActiveDiscount']),
		...mapGetters("app", ["currentCurrencySymbol"]),
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		getTitle(item) {
			return item.title || item.name;
		},
		goToSliderItem(item, category) {
			if (category == 'Cases') {
				this.$router.push({ path: `/packs/${item.slug}` });
				return;
			}
			let self = this;

			setTimeout(() => {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
	},
}
</script>
<style scoped lang="scss">
.item-wrapper {

	.discount-badge {
		position: absolute;
		top: 4px;
		right: 10px;
		display: flex;
		align-items: center;
		// background-color: rgba(0, 0, 0, 0.7);
		border-radius: 12px;
		// padding: 4px 8px;
		z-index: 1;
	}

	.discount-icon {
		width: 24px;
		height: 24px;
		pointer-events: none;
		// margin-right: 4px;
	}

}
</style>