<template>
    <div class="select-container currency-select-container" v-if="currentCurrency" @click="toggleDropdown">
        <div class="dropdown" @blur="isOpen = false">
            <div class="dropdown-selected" ref="dropdownSelected">
                <!-- {{ currentCurrencySymbol }} -->
                {{ currentCurrency }}
                <span class="dropdown-arrow" :class="{ 'arrow-up': isOpen }">▾</span>
            </div>
            <transition name="fade">
                <div v-if="isOpen" class="dropdown-menu">
                    <div v-for="currency in allCurrencies" :key="currency.code" class="dropdown-item"
                        @click.stop="selectCurrency(currency.code)">
                        {{ currency.code }} ({{ currency.symbol }})
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        ...mapGetters("app", ["currentCurrency", "currentCurrencySymbol", "allCurrencies"]),
    },
    methods: {
        ...mapActions('app', ['changeCurrency']),
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectCurrency(currencyCode) {
            this.changeCurrency(currencyCode);
            this.isOpen = false;
        },
        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
        handleScroll() {
            this.isOpen = false;
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdown);
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style scoped>
.select-container {
    position: relative;
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

.dropdown {
    cursor: pointer;
    user-select: none;
}

.dropdown-selected {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-arrow {
    margin-left: 10px;
    font-size: 25px;
    color: #fff;
    user-select: none;

}

.dropdown-menu {
    position: absolute;
    left: 0;
    top: 110%;
    background: var(--bg-main-blue);
    border: 1px solid #ddd;
    width: 100%;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-item:hover {
    background-color: var(--bg-second-black);
}

.dropdown-arrow.arrow-up {
    transform: rotate(-180deg);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>