<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="footer-section-wrapper">
        <div class="left">
          <a href="index.html" class="logo">
            <img src="./../assets/logo.svg" class="img" />
          </a>
          <div class="text footer-requisites">
            <div class="desc">{{ $parent.footerRequisites }}</div>
          </div>
          <div class="text">
            <!-- <div class="desc">{{ $t("The Cardholder himself is responsible for compliance with the rules of the copyright holder") }}.</div> -->
          </div>
        </div>
        <ul class="nav">
          <template v-for="(item, i) in $parent.nav">
            <li class="nav__item" :key="i">
              <router-link :to="item.link">
                {{ $t(item.title) }}
              </router-link>
            </li>
          </template>
          <div class="social-links-wrapper">
            <div class="social-links">
              <a :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link">
                <img :src="link.img" class="link-img" />
              </a>
            </div>
          </div>
        </ul>
        <ul class="nav">
          <template v-for="item in $parent.textPageList">
            <li class="nav__item" :key="item.id">
              <a @click="$parent.goToPage(item.id)">
                {{ $t(item.title) }}
              </a>
            </li>
          </template>
        </ul>
        <div class="right">
          <ul class="list">
            <li class="item" v-if="$parent.footerSupportEmail">
              <div class="title support">{{ $t("Support") }}:</div>
              <div class="desc support-email">{{ $parent.footerSupportEmail }}</div>
            </li>

            <ul class="payment-methods-list" v-if="paymentMethodsList && paymentMethodsList.length">
              <li v-for="image in allImages" :key="image" class="image-wrapper">
                <img :src="image" class="support-icon img" />
              </li>
            </ul>

          </ul>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === 'card' && method.title === 'Visa/Mastercard') {
        images.push(require('@/assets/visa.svg'), require('@/assets/mc_symbol.svg'));
        // images.push(require('@/assets/3ds.png'));
      } else if (method.code === 'eps' || method.title === 'EPS') {
        images.push(require('@/assets/eps.svg'));
      } else if (method.code === 'trustly' || method.title === 'Trustly') {
        images.push(require('@/assets/trustly.svg'));
      } else if (method.code === 'giropay' || method.title === 'GiroPay') {
        images.push(require('@/assets/giropay.svg'));
      } else if (method.code === 'blik' || method.title === 'Blik') {
        images.push(require('@/assets/blik.svg'));
      } else if (method.code === 'paidby' || method.title === 'Paidby') {
        images.push(require('@/assets/paidby.svg'));
      } else if (method.code === 'klarna' || method.title === 'Klarna') {
        images.push(require('@/assets/klarna.svg'));
      } else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
        images.push(require('@/assets/sofortuber.svg'));
      } else if (method.code === 'ideal' || method.title === 'iDEAL') {
        images.push(require('@/assets/ideal.svg'));
      } else if (method.code === 'bancontact' || method.title === 'Bancontact') {
        images.push(require('@/assets/bancontact.svg'));
      }
      return images;
    }

  },
  mounted() { },

  computed: {
    paymentMethodsList() {
      if (!this.$parent.paymentMethods) return [];

      const flatPaymentMethods = [].concat(...Object.values(this.$parent.paymentMethods));

      const uniquePaymentMethodsMap = new Map();
      flatPaymentMethods.forEach(pm => {
        const uniqueKey = `${pm.title}-${pm.code}`;
        if (!uniquePaymentMethodsMap.has(uniqueKey)) {
          uniquePaymentMethodsMap.set(uniqueKey, pm);
        }
      });

      const uniquePaymentMethods = Array.from(uniquePaymentMethodsMap.values());
      return uniquePaymentMethods;
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    }

  }

};
</script>

<style lang="scss" scoped>
.footer .footer-section-wrapper .payment-methods-list {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 10px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 35px;
    height: 35px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}

.social-links-wrapper{
  margin-top: 32px;
}
</style>
