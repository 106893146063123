import axios from "@/axios";
import i18n from "@/i18n";

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem("language") || "",
  currencies: [],
  currentCurrency: localStorage.getItem("currency") || "",
  currentCurrencySymbol: "",
};

const getters = {
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.title;
      }
    }
    return "";
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.code;
      }
    }
    return "";
  },
  allLanguages: (state) => state.languages,
  currentCurrency: (state) => state.currentCurrency,
  currentCurrencySymbol: (state) => state.currentCurrencySymbol,
  allCurrencies: (state) => state.currencies,
};

const actions = {
  fetchLanguages: async ({ commit, dispatch, state: { currentLanguage } }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "languages");
      if (status === "OK") {
        commit("setLanguages", payload);

        if (payload.length === 0) {
          commit("setCurrentLanguage", "");
          return; // Exit early if there are no languages
        }

        // Find the default language option
        const defaultLanguage = payload.find((lang) => lang.default === 1);

        // Check if the current language is valid
        const isValidLanguage = payload.some(
          (lang) => lang.id == currentLanguage
        );

        if (!isValidLanguage) {
          // If the current language is not valid, use the default language or first available language
          const newLocaleId = defaultLanguage
            ? defaultLanguage.id
            : payload[0].id;
          await dispatch("changeLocale", newLocaleId);
        } else {
          await dispatch("changeLocale", currentLanguage);
        }
      }
    } catch (e) {
      console.log(e);
      commit("setLanguages", []);
      commit("setCurrentLanguage", "");
    } finally {
      commit("setLoading", false);
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit("setCurrentLanguage", localeId);

      try {
        const { data } = await axios.get("/localizations", {
          params: {
            lang_id: localeId,
          },
        });

        if (data) {
          let translations = {};

          if (Array.isArray(data) && data.length) {
            data.forEach(
              (translation) =>
                (translations[Object.keys(translation)[0]] =
                  Object.values(translation)[0])
            );
          } else if (typeof data === "object") {
            translations = data;
          }

          const { currentLanguageCode } = getters;

          i18n.setLocaleMessage(currentLanguageCode, translations);
          i18n.locale = currentLanguageCode;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },

  fetchCurrencies: async ({ commit, dispatch }) => {
    try {
      commit("setLoading", true);
      const { data } = await axios.get(process.env.VUE_APP_API + "currencies");
      if (data.status === "OK") {
        const currencies = data.payload.map((currency) => ({
          code: currency.code,
          symbol: currency.symbol,
          value: currency.value,
        }));
        commit("setCurrencies", currencies);
        dispatch("validateAndSetCurrency");
      }
    } catch (e) {
      console.log(e);
      commit("setCurrencies", []);
    } finally {
      commit("setLoading", false);
    }
  },

  validateAndSetCurrency: ({ commit, state }) => {
    const storedCurrency = localStorage.getItem("currency");
    const validCurrency = state.currencies.find(
      (c) => c.code === storedCurrency
    );

    if (validCurrency) {
      commit("setCurrentCurrency", validCurrency);
    } else {
      const defaultCurrency =
        state.currencies.find((c) => c.code === "EUR") || state.currencies[0];
      if (defaultCurrency) {
        commit("setCurrentCurrency", defaultCurrency);
      } else {
        localStorage.removeItem("currency");
        commit("setCurrentCurrency", null);
      }
    }
  },

  changeCurrency: ({ commit, state }, currencyCode) => {
    const currency = state.currencies.find((c) => c.code === currencyCode);
    if (currency) {
      commit("setCurrentCurrency", currency);
    }
  },

  // changeCurrency: async ({commit, getters}, lcurrency) => {
  //     if (localeId) {
  //         commit('setCurrentLanguage', localeId);
  //
  //         try {
  //             const {data} = await axios.get('/localizations', {
  //                 params: {
  //                     lang_id: localeId
  //                 }
  //             });
  //
  //             if (data) {
  //                 let translations = {};
  //
  //                 if (Array.isArray(data) && data.length) {
  //                     data.forEach((translation) => translations[Object.keys(translation)[0]] = Object.values(translation)[0])
  //                 } else if (typeof data === 'object') {
  //                     translations = data;
  //                 }
  //
  //                 const {currentLanguageCode} = getters;
  //
  //                 i18n.setLocaleMessage(currentLanguageCode, translations)
  //                 i18n.locale = currentLanguageCode;
  //             }
  //         } catch (e) {
  //             console.log(e);
  //         }
  //     }
  // },
  // checkCurrency: async ({commit}) => {
  //
  // }
};

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language;
    if (language) {
      localStorage.setItem("language", language);
    } else {
      localStorage.removeItem("language");
    }
  },

  setCurrencies: (state, currencies) => {
    state.currencies = currencies;
  },
  setCurrentCurrency: (state, currency) => {
    if (currency) {
      state.currentCurrency = currency.code;
      state.currentCurrencySymbol = currency.symbol;
      localStorage.setItem("currency", currency.code);
    } else {
      state.currentCurrency = "";
      state.currentCurrencySymbol = "";
      localStorage.removeItem("currency");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
