<template>
    <div class="random-cases-section" v-if="randomCases && randomCases.length">
        <div class="wrapper">
            <div class="section">
                <div class="slider-header">
                    <div class="left">
                        <div class="top">
                            <div class="new-badge">{{ $t(`New!`) }}</div>
                        </div>
                        <div class="bottom">
                            <h2 class="slider-title">{{ $t(`Rust random cases`) }}</h2>
                            <i class="info-icon" v-tooltip="{
        content: tooltipContent,
        html: true,
        triggers: ['click'],
        placement: 'top',
        autoHide: true,
        theme: 'random-cases-tooltip'
    }">
                                <img src="@/assets/tooltip.svg" alt="">
                            </i>
                        </div>
                    </div>
                    <div class="right">
                        <router-link to="/products/rust?type=cases" class="link">{{ $t(`View all`) }}</router-link>
                    </div>
                </div>
            </div>
            <div class="random-cases-slider" @click="handleClick">
                <VueSlickCarousel v-bind="slickOptions" ref="carousel">
                    <div v-for="(card, index) in randomCases" :key="index" class="card" :data-slug="card.slug">
                        <div class="card-wrapper" :class="card.type">
                            <div class="card-content">
                                <div class="price-badge">{{ card.price }} {{ currentCurrencySymbol }}</div>
                                <div class="card-image-container">
                                    <div class="ellipse-background" :class="card.type"></div>
                                    <img :src="card.image_url" :alt="card.name" class="card-image">
                                </div>
                                <div class="card-footer">
                                    <h3>{{ card.name }}</h3>
                                    <p v-html='card.description'></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'RandomCasesSlider',
    components: { VueSlickCarousel },
    created() {
        this.fetchRandomCases();
    },
    mounted() {
        this.$nextTick(this.initializeCarousel);
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            tooltipContent: `
                <h2 class="tooltip-title">Random Packs</h2>
                <p class="tooltip-description">
                Please be advised that items obtained from these cases are distributed on a purely random basis. The value of the items you receive can vary significantly, generally ranging from equal to the cost of the case itself to potentially slightly exceeding the case's value. It's important to understand that due to the random nature of item distribution, we cannot guarantee the exact value or specific items you will receive from any given case. By purchasing a case, you acknowledge and accept these terms and conditions of the platform. If you have any questions or concerns, please contact our customer support before making a purchase.
                </p>
            `,
            slickOptions: {
                infinite: true,
                slidesToShow: this.calculateSlidesToShow(),
                slidesToScroll: this.calculateSlidesToScroll(),
                draggable: true,
                // centerMode: false,
                // initialSlide: 0, 
                autoplay: true,
                autoplaySpeed: 4000,
                // speed: 500,
                arrows: true,
                dots: false,
                // variableWidth: true,
                // centerMode: true,
                // centerPadding: '60px',
            },
            randomCases: [
            ],
        }
    },
    computed: {
        ...mapGetters("app", ["currentCurrencySymbol", "currentCurrency"]),
    },
    watch: {
        currentCurrency() {
            this.fetchRandomCases();
        }
    },
    methods: {
        addToCart(item, id, item_type) {
            this.$parent.addToCart(item, id, item_type);
        },
        handleResize() {
            this.slickOptions.slidesToShow = this.calculateSlidesToShow();
            this.slickOptions.slidesToScroll = this.calculateSlidesToScroll();
        },
        calculateSlidesToShow() {
            let containerWidth;
            if (innerWidth >= 1440) {
                containerWidth = 1200; // fixed container width for large screens
            } else {
                containerWidth = innerWidth - 16; // subtract 10px padding from both sides
            }
            const cardWidth = 303;
            const gap = 32;
            const slidesToShow = Math.floor((containerWidth + gap) / (cardWidth + gap));
            return slidesToShow;
        },
        calculateSlidesToScroll() {
            return this.calculateSlidesToShow();
        },
        initializeCarousel() {
            const carousel = this.$refs.carousel;
            if (carousel && carousel.$el) {
                const slickList = carousel.$el.querySelector('.slick-list');
                if (slickList) {
                    slickList.addEventListener('mousedown', () => {
                        this.isDragging = false;
                    });
                    slickList.addEventListener('mousemove', () => {
                        this.isDragging = true;
                    });
                    slickList.addEventListener('mouseup', () => {
                        setTimeout(() => {
                            this.isDragging = false;
                        }, 10);
                    });
                }
            }
        },
        handleClick(event) {
            const card = event.target.closest('.card');
            if (card && !this.isDragging) {
                const slug = card.dataset.slug;
                this.$router.push(`/packs/${slug}`);
            }
        },
        async fetchRandomCases() {
            this.$http.get(process.env.VUE_APP_API + 'packs')
                .then((res) => {
                    if (res.status == 200) {
                        this.randomCases = res.data.payload;
                    }
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.$parent.openSignInModal();
                    }
                })
        },
    }
}
</script>


<style lang='scss' scoped>
.random-cases-section {
    margin-top: 160px;

    .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 65px;
        margin-bottom: 40px;

        .left {

            .bottom {
                display: flex;
                gap: 11px;
            }

            .slider-title {
                font-weight: 600;
                font-size: calc(clamp(1.313rem, 0.83rem + 2.411vw, 3rem));
                color: white;
            }

            .info-icon img {
                cursor: pointer;
            }
        }

        .link {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #fff;
        }

    }


}

.random-cases-slider {
    height: 494px;
}

.slider-header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 303px;
    height: 494px;
    padding: 16px 0;
    border-radius: 24px;
    position: relative;
    transition: all 0.15s ease;
    margin: 0 16px;
    cursor: pointer;
}

.card-wrapper .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.type-1.card-wrapper:hover {
    border-top: 3px solid rgba(255, 255, 255, 0.1);
    border-bottom: 3px solid rgba(255, 255, 255, 0.1);
}

.type-2.card-wrapper:hover {
    border-top: 3px solid rgba(86, 167, 161, 0.5);
    border-bottom: 3px solid rgba(86, 167, 161, 0.5);
}

.type-3.card-wrapper:hover {
    border-top: 3px solid rgba(233, 98, 22, 0.3);
    border-bottom: 3px solid rgba(233, 98, 22, 0.3);
}

.type-4.card-wrapper:hover {
    border-top: 3px solid rgba(249, 160, 0, 1);
    border-bottom: 3px solid rgba(249, 160, 0, 1);
}

.price-badge {
    /* position: absolute; */
    /* top: 10px; */
    /* left: 10px; */
    width: max-content;
    max-width: 150px;
    height: 38px;
    background: #56A7A1;
    color: white;
    border-radius: 24px;
    z-index: 2;
    padding: 8px 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin-bottom: 12px;
}

.card-image-container {
    position: relative;
    width: 292px;
    height: 292px;
    pointer-events: none;
    margin-bottom: 12px;
    /* margin: 0 auto; */
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ellipse-background {
    position: absolute;
    width: 292px;
    height: 292px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ellipse-background.type-1 {
    background-color: rgba(255, 255, 255, 0.1);
    filter: blur(20px);
}

.ellipse-background.type-2 {
    background-color: rgba(86, 167, 161, 0.5);
    filter: blur(20px);
}

.ellipse-background.type-3 {
    background-color: rgba(233, 98, 22, 0.3);
    filter: blur(20px);
}

.ellipse-background.type-4 {
    background-color: rgba(249, 160, 0, 1);
    filter: blur(20px);
}

.card-image {
    position: relative;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

h3 {
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 4px;
    color: #fff;
    height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 290px;
    text-align: center;
}

p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    height: 44px;
    color: #fff;
    opacity: 0.5;
    text-align: center;
    max-width: 206px;
    text-wrap: wrap;
    max-height: 44px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new-badge {
    background: #EC7633;
    color: white;
    text-transform: uppercase;
    border-radius: 24px;
    width: 64px;
    height: 24px;
    padding: 4px 16px;

    font-size: 12px;
    line-height: 16px;
    text-wrap: nowrap;
}

@media (max-width: 670px) {

    .card-wrapper {
        margin: 0;
        width: 288px;
    }

}

@media (max-width: 460px) {
    // .random-cases-section {
    //     .slider-header {
    //         line-height: 26px;
    //         flex-direction: column;
    //         align-items: flex-start;
    //     }
    // }
}
</style>