<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="displayItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title added" v-if="$parent.addToCartChosenItem == displayItem.id">{{ $t(`Added`) }}</span>
                    </transition>
                    <img :src="getImageUrl(displayItem)" class="img" />
                  </div>
                  <div class="text">
                    <div class="desc" v-if="itemType == 'pack'">{{ 'Pack' }}</div>
                    <div class="desc" v-else-if="itemType == 'item'">{{ displayItem.type }}</div>
                    <div class="title">{{ displayItem.title || displayItem.name }}</div>
                    <div class="desc" v-if="displayItem.quality && itemType == 'item'">
                      <span class=" label">Rarity: </span>
                      <span :style="{ color: getColor(displayItem.quality) }">{{ displayItem.quality }}</span>
                    </div>
                    <div class="desc" v-if="displayItem.hero && itemType == 'item'">
                      <span class="label">Hero: </span>
                      {{ displayItem.hero }}
                    </div>
                    <div class="desc" v-if="displayItem.description">{{ displayItem.description }}</div>
                    <div class="title price">{{ displayItem.price }} <span class="currency">{{ currentCurrencySymbol
                        }}</span></div>

                    <div class="content_desc" v-if="itemType == 'pack'" v-html="displayItem.content_desc">
                    </div>

                    <button class="button" @click="addToCart(displayItem, displayItem.id, displayItem.item_type)">
                      <span>{{ $t(`Add to Cart`) }}</span>
                    </button>

                    <div class="disclaimer" v-if="itemType == 'pack'">
                      <img src="@/assets/tooltip.svg" alt="">
                      <p>
                        Please be advised that items obtained from these cases are distributed on a purely random basis.
                        The value of the items you receive can vary significantly, generally ranging from equal to the
                        cost of the case itself to potentially slightly exceeding the case's value. It's important to
                        understand that due to the random nature of item distribution, we cannot guarantee the exact
                        value or specific items you will receive from any given case. By purchasing a case, you
                        acknowledge and accept these terms and conditions of the platform. If you have any questions or
                        concerns, please contact our customer support before making a purchase.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency', 'id', 'slug'],
  data() {
    return {
      packData: null,
      itemType: "item",
      colorMap: {
        "common": "#b0c3d9",
        "uncommon": "#5e98d9",
        "rare": "#4b69ff",
        "mythical": "#8847ff",
        "immortal": "#e4ae39",
        "legendary": "#d32ce6",
        "seasonal": "#fff34f",
        "arcana": "#ade55c",
        "ancient": "#eb4b4b",
      }
    }
  },
  computed: {
    ...mapGetters("app", ["currentCurrencySymbol", "currentCurrency"]),

    displayItem() {
      return this.packData || this.$parent.productPageItem;
    }
  },
  watch: {
    currentCurrency() {
      if (this.itemType === 'pack') {
        this.fetchPackData();
      }
    }
  },
  mounted() {
    if (this.id) {
      this.$parent.getProductPageContent(this.id);
      this.itemType = 'item';
    } else if (this.slug) {
      this.itemType = 'pack';
      this.fetchPackData();
    }
  },
  methods: {
    getColor(attribute) {
      return this.colorMap[attribute.toLowerCase()] || '';
    },
    fetchPackData() {
      this.$http.get(`${process.env.VUE_APP_API}packs/${this.slug}`)
        .then(response => {
          this.packData = response.data.payload;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addToCart(item, id, item_type) {
      this.$parent.addToCart(item, id, item_type);
    },
    getImageUrl(item) {
      if (this.packData) {
        return item.image_url;
      }
      return this.$parent.imgDomain + item.img_url;
    }
  }
}
</script>

<style lang="scss" scoped>
.content_desc {
  margin-top: 20px;
  color: white;
}

.disclaimer {
  color: white;
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-align: left;

  h2 {
    font-size: 14px;
  }
}
</style>